<template>
  <div id="download">
    <b-overlay
      :show="show"
      variant="transparent"
      :opacity="1.00"
      :blur="'2px'"
      rounded="lg"
    >
      <b-row class="mb-2 ">
        <!-- Input de búsqueda -->
        <b-col sm="9">
          <b-form-input
            v-model="searchInput"
            :disabled="seleccionar"
            :placeholder="$t('BuscarContenidos')"
            autofocus
            autocomplete="off"
            @keyup="onKeyUp()"
          />
        </b-col>

        <!-- Botón de búsqueda -->
        <b-col>
          <b-button
            variant="info"
            :disabled="seleccionar"
            @click.stop.prevent="onKeyUp(true)"
          >
            <span style="vertical-align: text-top">{{ $t('Search') }}</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-select
            v-model="orderBy"
            :options="optionsOrder"
          />
        </b-col>
      </b-row>
      <!-- CONTENIDOS -->
      <b-row class="mb-2 mt-2">
        <b-col>
          <span v-if="search && search.length != 0">{{ $t('resultFounds', { length: search.length }) }}</span>
          <span v-else>{{ $t('resultNotFound') }}</span>
        </b-col>
      </b-row>
      <b-table-simple
        ref="refContentListTable"
        class="mt-2 mb-1"
        show-empty
        hover
        :empty-text="$t('emptyText')"
        responsive
        primary-key="id"
        :no-border-collapse="true"
      >
        <b-thead>
          <b-tr>
            <b-th
              v-for="i in tableColumns"
              :key="i.key"
            >
              {{
                i.label
              }}
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="item in search"
            :key="item.node.id"
          >
            <b-td>
              <div
                class="d-flex"
                style="align-items: center"
              >
                <b-img-lazy
                  id="avatar"
                  class="mr-2"
                  rounded="lg"
                  :src="buildImageUrl(item)"
                  @error="errorImg"
                />
                <p>{{ item.node.name }}</p>
              </div>
            </b-td>

            <b-td>
              <span
                class="download-acortarParrafo"
              >
                {{ isEmpty(item.node.description)?item.node.shortDescription:item.node.description }}
              </span>
            </b-td>

            <b-td>
              <div
                v-if="showDownload(item.node)"
                class="align-items-center"
              >
                <feather-icon
                  icon="DownloadIcon"
                  class="text-primary"
                  size="18"
                  @click="downloadButton(item.node)"
                />
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <div
        v-if="hasMore"
        class="d-flex justify-content-center align-self-center mt-2"
      >
        <small>{{ $t('downToSee') }}</small><feather-icon
          icon="ArrowDownIcon"
          size="20"
          class="ml-50"
        />
      </div>
      <b-overlay
        :show="busy"
        no-wrap
        variant="transparent"
        :opacity="1.00"
        :blur="'2px'"
        rounded="lg"
      >
        <template #overlay>
          <div class="text-center p-4">
            <feather-icon
              variant="link"
              icon="DownloadIcon"
              size="10px"
            />
            <h1 variant="link">
              Download...
            </h1>
            <b-progress
              min="0"
              :max="totalContadorDescarga"
              :value="recibiendoDescarga"
              variant="link"
              height="11px"
              class="mx-n4 rounded-0"
            />
          </div>
        </template>
      </b-overlay>
    </b-overlay>
    <div ref="infiniteScroll" />
  </div>
</template>

<script>
import '@core/assets/fonts/icons/css/all.css'
import '@core/assets/fonts/icons/scss/fontawesome.scss'
import {
  showToast,
  isEmpty, messageError,
} from '@/store/functions'
import { getUserData } from '@/auth/utils'
import axios from '@axios'

import {
  BCol,
  BImgLazy,
  BRow,
  BButton,
  BFormInput,
  BOverlay,
  BFormSelect,
  BProgress,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
} from 'bootstrap-vue'

const noCover = require('@/assets/images/backend/nocover.jpeg')
const fileError = require('@/assets/images/backend/file-error.png')

export default {
  name: 'Download',
  components: {
    BCol,
    BTableSimple,
    BProgress,
    BThead,
    BImgLazy,
    BTr,
    BTh,
    BOverlay,
    BTd,
    BTbody,
    BRow,
    BFormInput,
    BFormSelect,
    BButton,
  },
  data() {
    return {
      orderBy: true,
      isEmpty,
      busy: false,
      dataOverlay: [],
      optionsOrder: [
        { value: true, text: this.$t('orderByRecents') },
        { value: false, text: this.$t('orderByOld') },
      ],
      tableColumns: [
        { key: 'name', label: this.$t('firstName') },
        { key: 'emisionDate', label: this.$t('createContent.description') },
        { key: 'download', label: this.$t('download') },
      ],
      searchInput: '',
      search: [],
      show: false,
      userData: getUserData(),
      selected: null,
      options: [
        { value: null, text: this.$t('code.status') },
        { value: 'RED', text: `🔴 ${this.$t('ecommerce.NoPublic')}` },
        { value: 'YEW', text: `🟡 ${this.$t('dataGeneric.internal')}` },
        { value: 'GRE', text: `🟢 ${this.$t('ecommerce.public')}` },
      ],
      seleccionar: false,
      selectContent: [],
      headers: {},
      page: 1,
      totalContadorDescarga: 0,
      recibiendoDescarga: 0,
      isLoading: false,
      hasMore: true,
    }
  },
  watch: {

    orderBy() {
      this.onKeyUp(true)
    },

  },
  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
    } catch (error) {
      console.log(error)
    }

    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }

    window.addEventListener('scroll', this.handleScroll)

    this.onKeyUp(true)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },

  methods: {

    handleScroll() {
      const element = this.$refs.infiniteScroll
      const rect = element.getBoundingClientRect()
      if (rect.top <= window.innerHeight && this.hasMore && !this.isLoading) {
        this.getContents()
      }
    },
    downloadButton(node) {
      this.$swal({
        title: this.$t('downloadTitle'),
        text: this.$t('downloadQuest'),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: this.$t('download'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.busy = true
          this.dataOverlay = node
          this.onOK()
        } else {
          this.onCancel()
        }
      }).catch(() => {

      })
    },
    onCancel() {
      this.busy = false
    },
    onOK() {
      this.totalContadorDescarga = 0
      this.recibiendoDescarga = 0
      this.downloadFile(this.dataOverlay)
    },
    downloadFile(item) {
      if (!item || !item.contentResources || !item.contentResources.edges.length) {
        showToast('No hay datos disponibles para descargar.', 2, this)
        console.error('No hay datos disponibles para descargar.')
        return
      }

      const { resource } = item.contentResources.edges[0].node

      if (!resource) {
        showToast('No se encontró el recurso.', 2, this)
        console.error('No se encontró el recurso.')
        return
      }

      const downloadUrl = resource.directUrl || resource.bucketUrl

      if (!downloadUrl) {
        showToast('No hay ninguna URL válida para descargar.', 2, this)
        console.error('No hay ninguna URL válida para descargar.')
        return
      }
      const xhr = new XMLHttpRequest()
      xhr.open('GET', downloadUrl, true)
      xhr.responseType = 'blob'

      xhr.onload = function () {
        const urlCreator = window.URL || window.webkitURL
        const videoUrl = urlCreator.createObjectURL(this.response)
        const tag = document.createElement('a')
        tag.href = videoUrl
        tag.download = item.name
        document.body.appendChild(tag)
        tag.click()
        document.body.removeChild(tag)
      }
      xhr.onloadend = () => {
        this.busy = false
      }
      xhr.onprogress = event => {
        this.totalContadorDescarga = event.total
        this.recibiendoDescarga = event.loaded
      }
      xhr.onerror = err => {
        console.log(err)
      }
      xhr.send()
    },
    showDownload(item) {
      // Validar que el item y las propiedades necesarias existan
      if (!item || !item.contentResources || !item.contentResources.edges.length) {
        console.error('No hay datos disponibles para descargar.')
        return false // No se puede descargar
      }

      const { resource } = item.contentResources.edges[0].node

      // Validar que el recurso exista
      if (!resource) {
        console.error('No se encontró el recurso.')
        return false // No se puede descargar
      }

      // Obtener la URL de descarga
      const downloadUrl = resource.directUrl || resource.bucketUrl

      // Validar que la URL termine con ".mp4"
      if (downloadUrl && downloadUrl.endsWith('.mp4')) {
        return true // Se puede descargar
      }

      return false // No se puede descargar
    },
    getContents() {
      const { headers } = this
      this.show = true
      this.isLoading = true
      const query = `
         query{
        allContents(
          search:"${this.searchInput}",
          orderBy:${this.orderBy === true ? '"-createdAt"' : '"createdAt"'},
          first:12,
          state:"GRE",
          isActive:true,
          offset:${12 * (this.page - 1)},
          client:"${this.userData.profile.client.id}"               
          ){
            pageInfo {
            hasNextPage
            
          }
          totalCount
          edges {
            node {
              id
              name
              imageUrl
              type
              emisionDate
              publishDate
              expirationDate
              description
              shortDescription
              isActive
              state
              contentSlide {
                totalCount                                
              }
              contentContentOrder
              {
                  totalCount
                  edges {
                    node {
                      id
                      order
                      category {
                        id
                        name
                      }
                    }
                  }
                }
              categories {
                totalCount
                edges {
                  node {
                    id
                    name
                    isFinal
                  }
                }
              }
              contentResources(isInitial:true){
                edges{
                  node{
                    resource{
                      directUrl
                      bucketUrl
                      typeStream
                    }
                  }
                }
              }

            }
          }
        }
      }
        `
      axios
        .post('', {
          query,
        }, { headers })
        .then(response => {
          messageError(response, this)

          const srch = response.data.data.allContents.edges
          if (response.data.data.allContents.pageInfo.hasNextPage) {
            this.search = [...this.search, ...srch]
            this.page += 1
          } else {
            this.search = [...this.search, ...srch]
            this.hasMore = false
          }
          this.isLoading = false
          this.show = false
        }).catch(res => {
          console.log(res)
        })
    },
    buildImageUrl(folder) {
      return folder.node.imageUrl == null || folder.node.imageUrl.length === 0
        ? noCover
        : folder.node.imageUrl
    },
    errorImg(e) {
      e.target.src = fileError
      // "https://cdn.iconscout.com/icon/free/png-256/file-error-1470245-1244839.png";
    },
    onKeyUp(pass = false) {
      let keycode = 0
      try {
        // eslint-disable-next-line no-restricted-globals
        keycode = event.keyCode || event.which
        // eslint-disable-next-line no-empty
      } catch (error) {

      }

      if ((keycode === 13 || pass) && !this.show) {
        this.search = []
        this.hasMore = true
        this.page = 1
        this.getContents()
      }
    },

    fechaFormato(value) {
      const date = new Date(value)
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }

      return value != null
        ? new Intl.DateTimeFormat('default', options).format(date)
        : value
    },

  },
}
</script>

<style scoped>
#download .card-img-top {
  /*width: 100%;
  height: 25vh;
  object-fit: cover;*/
}

#download .actionsBar {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

#download .folder img {
  height: 11vw;
  object-fit: cover;

}

@media screen and (max-width: 767px) {
  #download .folder img {
    height: 40vw;
    object-fit: cover;
  }
}

@media screen and (min-width: 767px) {

  #download .folder img {
    height: 23vw;
    object-fit: cover;

  }
}

@media screen and (min-width: 1200px) {

  #download .folder img {
    height: 12vw;
    object-fit: cover;

  }
}

#download .card-body {
  padding: 1rem;
}

#download .card-body h4 {
  font-size: 1rem !important;
}

#download .card-header {
  padding: 1rem;
}

#download .card-header h4 {
  font-size: 1rem !important;
}

#download .card-body .actionsBar {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;

}

#download .right {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

#download .left {
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

#download .list-group-item {
  transition: all 1s;
}

#download #acortar {
  overflow: hidden;
  text-overflow: ellipsis;
}

#download #acortarPadre {
  overflow: hidden;
  white-space: nowrap;
}

#download .vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__clear,
.vs--disabled .vs__search,
.vs--disabled .vs__selected,
.vs--disabled .vs__open-indicator {
  cursor: not-allowed;
  background-color: transparent !important;
  opacity: 0.8 !important;
  /* background-color: #f8f8f8; */
}
.download-acortarParrafo {
  display: block;
  display: -webkit-box;
  /* height: 559px; */
  font-size: 14px;
  line-height: 1.4;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
